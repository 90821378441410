var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('filter-form-query-builder'),_c('v-data-table',{staticClass:"table-data-app",attrs:{"hide-default-footer":"","items":_vm.dataItems,"headers":_vm.headerArray,"items-per-page":-1,"fixed-header":""},on:{"update:sort-by":_vm.handleUpdateSortBy,"update:sort-desc":_vm.handleUpdateSortDesc},scopedSlots:_vm._u([{key:"body",fn:function(){return [(_vm.dataItems.length)?_c('tbody',_vm._l((_vm.dataItems),function(data,dataItemIndex){return _c('tr',{key:dataItemIndex + '_dataItems'},[_c('td',{staticClass:"text-center"},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-horizontal")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function () { return _vm.openFormEditItem(data); }}},[_c('v-list-item-title',{domProps:{"textContent":_vm._s(_vm.$t('user.userList.actions.edit'))}})],1),_c('v-divider'),_c('v-list-item',{on:{"click":function () { return _vm.clickDeleteItem(data.id); }}},[_c('v-list-item-title',{domProps:{"textContent":_vm._s(_vm.$t('user.userList.actions.delete'))}})],1),_c('v-divider'),_vm._l((_vm.referenceableToFieldAppList),function(item,i){return _c('v-list-item',{key:i,attrs:{"disabled":_vm.dataSeletectedApp && _vm.dataSeletectedApp.id !== _vm.viewAppId},on:{"click":function () { return _vm.openFormEditChildItem(item,data); }}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('user.userList.actions.add'))+" "+_vm._s(item ? item.keyAttribute : ''))])],1)})],2)],1)],1),_c('td',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',_vm._g(_vm._b({attrs:{"color":"grey lighten-1","size":"18"},domProps:{"textContent":_vm._s('mdi-content-copy')},on:{"click":function($event){return _vm.copyText(data.id)}}},'v-icon',attrs,false),on))],1)]}}],null,true)},[_c('span',{domProps:{"textContent":_vm._s(data.id)}})])],1),_vm._l((_vm.headers),function(metadata,headerIndex){return _c('td',{key:dataItemIndex + '_dataItems_headerIndex_' + headerIndex},[(_vm.isMedia(metadata))?_c('span',[_c('img',{directives:[{name:"show",rawName:"v-show",value:(metadata.type === 'photo'),expression:"metadata.type === 'photo'"},{name:"lazy",rawName:"v-lazy",value:(_vm.viewDataItem(metadata, data)),expression:"viewDataItem(metadata, data)"}],attrs:{"width":"80"}}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',_vm._g(_vm._b({attrs:{"color":"grey lighten-1","size":"18"},domProps:{"textContent":_vm._s('mdi-content-copy')},on:{"click":function($event){_vm.copyText(_vm.viewDataItem(metadata, data))}}},'v-icon',attrs,false),on))],1)]}}],null,true)},[_c('span',{domProps:{"textContent":_vm._s(_vm.viewDataItem(metadata, data))}})])],1):(metadata.type === 'json')?_c('pre',{staticClass:"format-show-value",domProps:{"textContent":_vm._s(_vm.viewDataItem(metadata, data))}}):(!_vm.isReferenceableToField(metadata))?_c('span',{staticClass:"format-show-value",domProps:{"textContent":_vm._s(_vm.viewDataItem(metadata, data))}}):(metadata.type === 'array')?_c('span',{staticStyle:{"font-size":"12px"}},_vm._l((_vm.viewDataItem(metadata, data)),function(ref,refIndex){return _c('p',{key:dataItemIndex + '_dataItems_headerIndex_' + headerIndex + '_refIndex_' + refIndex},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref$1){
var on = ref$1.on;
var attrs = ref$1.attrs;
return [_c('span',_vm._g(_vm._b({staticStyle:{"font-weight":"bold","cursor":"pointer"},domProps:{"textContent":_vm._s('Ref('+ ref.id +')')},on:{"click":function () { return _vm.openFormEditChildData(metadata,data,ref); }}},'span',attrs,false),on)),_c('v-avatar',{staticStyle:{"cursor":"pointer"},attrs:{"tile":"","size":"13"},on:{"click":function () { return _vm.clickDeleteReferenceChildItem(metadata,data, ref); }}},[_c('img',{attrs:{"src":"https://api.techupcorp.com/media/lCoxrooBHEE-qkZrziFx/file/ehcvisbqejvex/396113107_delete.png","alt":"*"}})]),_c('v-icon',{staticClass:"mr-2 ml-2 font-weight-bold",attrs:{"size":"13","color":"black"},on:{"click":function () { return _vm.clickDeleteChildItem(metadata,data, ref); }}},[_vm._v("mdi-link-variant-off")])]}}],null,true)},[_c('pre',{staticClass:"format-show-value",domProps:{"textContent":_vm._s(ref)}})])],1)}),0):(metadata.type === 'refs_array')?_c('span',{staticStyle:{"font-size":"12px"}},_vm._l((_vm.viewDataItem(metadata, data)),function(refId,refIndex){return _c('p',{key:dataItemIndex + '_dataItems_headerIndex_' + headerIndex + '_refIndex_' + refIndex},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticStyle:{"font-weight":"bold","cursor":"pointer"},domProps:{"textContent":_vm._s('Ref('+ refId +')')},on:{"click":function () { return _vm.openFormEditItem(data, metadata.keyAttribute); }}},'span',attrs,false),on))]}}],null,true)},[_c('pre',{staticClass:"format-show-value",domProps:{"textContent":_vm._s(_vm.viewReferenceableToField(metadata, data, refIndex))}})])],1)}),0):_c('v-tooltip',{staticStyle:{"font-size":"12px"},attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticStyle:{"font-weight":"bold","cursor":"pointer"},domProps:{"textContent":_vm._s(_vm.viewDataItem(metadata, data))},on:{"click":function () { return _vm.openFormEditItem(data, metadata.keyAttribute); }}},'span',attrs,false),on))]}}],null,true)},[_c('pre',{staticClass:"format-show-value",domProps:{"textContent":_vm._s(_vm.viewReferenceableToField(metadata, data))}})])],1)}),_c('td',[_vm._v(" "+_vm._s(data['updatedAt'] || data['createdAt'])+" ")])],2)}),0):_c('tbody',[_c('tr',{staticClass:"v-data-table__empty-wrapper"},[_c('td',{attrs:{"colspan":"4"}},[_vm._v(" No data available ")])])])]},proxy:true}])}),(_vm.dataItems.length)?_c('v-row',{staticClass:"ml-2 mt-4"},[_c('v-col',{attrs:{"cols":"4"}},[_c('v-pagination',{attrs:{"length":_vm.getPageTotal(_vm.totalItem, _vm.params.limit)},model:{value:(_vm.params.offset),callback:function ($$v) {_vm.$set(_vm.params, "offset", $$v)},expression:"params.offset"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-select',{attrs:{"items":_vm.limitOptions,"label":"Rows per page","dense":"","solo":""},model:{value:(_vm.params.limit),callback:function ($$v) {_vm.$set(_vm.params, "limit", $$v)},expression:"params.limit"}})],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }