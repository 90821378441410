<template>
  <v-dialog
    v-if="isShowDialogImportFileMedia"
    v-model="isShowDialogImportFileMedia"
    fullscreen
    :scrim="false"
    transition="dialog-bottom-transition"
  >
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
    >
      <v-card height="100vh">
        <v-card-title>
          <span class="primary--text font-weight-bold text-h5">Gallery</span>
          <v-spacer />
          <v-btn
            icon
            @click="isShowDialogImportFileMedia = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider />
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-select
                v-model="fileURL"
                :items="getDataFiles"
                item-value="fileURL"
                label="Select File"
                outlined
                dense
                @change="changeUrlFile"
              >
                <template v-slot:selection="{ item }">
                  <img v-if="fileTypeMedia === 'photo'" class="pa-2" height="150" :src="item.fileURL">
                  <p>{{ item.fileURL }}</p>
                </template>
                <template v-slot:item="{ item }">
                  <img v-if="fileTypeMedia === 'photo'" class="pa-2" height="80" :src="item.fileURL">
                  <p>{{ item.fileURL }}</p>
                </template>
              </v-select>
            </v-col>
            <v-col cols="12" :md="fileUpload ? 10 : 12">
              <v-file-input v-model="fileUpload" label="Choose File Media" show-size @change="changeUploadFile" />
            </v-col>
            <v-col v-show="fileUpload" cols="12" md="1">
              <v-btn
                color="primary"
                variant="flat"
                @click="submitUploadFile"
              >
                Upload File
              </v-btn>
            </v-col>
            <v-col v-if="fileTypeMedia === 'photo'" v-show="fileUpload" cols="12" align="center" justify="center">
              <v-img height="250" :src="urlPreview" cover />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="blue-grey"
            @click="isShowDialogImportFileMedia = false"
            v-text="$t('apps.appsForm.actions.close')"
          />
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
  import { sync } from 'vuex-pathify'
  export default {
    name: 'DialogImportFileMedia',
    data: () => ({
      urlPreview: '',
      fileUpload: null,
      valid: true,
      files: [],
      fileURL: '',
    }),
    computed: {
      ...sync('manageApps', [
        'isShowDialogImportFileMedia',
        'dataItem',
        'keyAttributeFileMedia',
        'fileTypeMedia',
      ]),
      getDataFiles() {
        return (this.files || []).filter(dataFilter => dataFilter.fileType === this.fileTypeMedia)
      },
    },
    watch: {
      isShowDialogImportFileMedia(visible) {
        if (visible) {
          this.getFiles()
          this.fileURL = this.dataItem[this.keyAttributeFileMedia] || ''
        } else {
          this.urlPreview = '';
          this.fileUpload = null;
          this.keyAttributeFileMedia = ''
          this.fileURL = ''
        }
      },
    },
    methods: {
      changeUrlFile() {
        this.dataItem[this.keyAttributeFileMedia] = this.fileURL
      },
      async getFiles() {
        const resultAPI = await this.$store.dispatch('manageApps/getFiles', this.getTokenLogin())
        if (!resultAPI.status) {
          return this.$toast.error(resultAPI.data.message)
        }
        this.files = resultAPI?.data?.files || []
      },
      changeUploadFile() {
        if (this.fileUpload) {
          this.urlPreview = URL.createObjectURL(this.fileUpload)
        } else {
          this.urlPreview = '';
        }
      },
      async submitUploadFile() {
        const formData = new FormData()
        formData.append('file', this.fileUpload, this.fileUpload?.name)
        formData.append('fileType', this.fileTypeMedia)
        const resultAPI = await this.$store.dispatch('manageApps/uploadFile', {
          tokenLogin: this.getTokenLogin(),
          formData,
        });
        if (!resultAPI.status) {
          return this.$toast.error(resultAPI.data.message)
        }
        this.$toast.success('Upload File')
        await this.getFiles()
        this.urlPreview = ''
        this.fileUpload = null
      },
    },
  }
</script>
