<template>
  <v-dialog
    v-if="isShowDialogItemFormUpdate"
    v-model="isShowDialogItemFormUpdate"
    fullscreen
    :scrim="false"
    transition="dialog-bottom-transition"
  >
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-card>
        <v-toolbar dark color="primary" style="position: fixed; z-index: 1; width: 100%;">
          <v-btn icon dark @click="isShowDialogItemFormUpdate = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>
            {{ itemId ? "Update Item" : "Add Item" }}</v-toolbar-title
          >
          <v-spacer />
          <v-toolbar-items>
            <v-btn
              color="info"
              :disabled="!valid"
              v-if="!isAddChildMetaData"
              @click="save"
              v-text="$t('user.userForm.actions.save')"
            />
            <v-btn
              color="info"
              :disabled="!valid "
              v-if="isAddChildMetaData"
              @click="saveAddChild"
              v-text="itemId ? $t('user.userForm.actions.updateChildItem') : $t('user.userForm.actions.saveAddChild')"
            />
            <v-btn
              color="info"
              :disabled="!valid"
              v-if="!isAddChildMetaData"
              @click="save('more')"
              class="ml-4"
              v-text="$t('user.userForm.actions.saveAndMore')"
            />
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text style="padding-top: 85px;">
          <div
            v-for="(metadata, keyAttributeIndex) in keyAttributes"
            :key="keyAttributeIndex + '_keyAttributeIndex'"
          >
            <div v-if="metadata.type === 'html'" class="pl-2 pb-2 form-field mb-5">
              <label
                class="label-title"
                >{{ metadata.keyAttribute }}
                <span
                  style="cursor: pointer; font-weight: bold"
                  @click="
                    $set(
                      showRawHTML,
                      metadata.keyAttribute,
                      !!!showRawHTML[metadata.keyAttribute]
                    )
                  "
                  >(View
                  {{ showRawHTML[metadata.keyAttribute] ? "" : "Raw" }}
                  HTML)</span
                >
              </label>
              <prism-editor
                v-if="showRawHTML[metadata.keyAttribute]"
                v-model="dataItem[metadata.keyAttribute]"
                class="my-editor"
                :highlight="highlighterHTML"
                line-numbers
              />
              <tiptap-vuetify
                v-else
                v-model="dataItem[metadata.keyAttribute]"
                :extensions="extensions"
                :placeholder="metadata.keyAttribute"
              />
            </div>
            <div
              v-else-if="metadata.type === 'json' || metadata.type === 'code' || metadata.type === 'rich_text'"
              class="pl-2 pb-2 form-field mb-5"
            >
              <label class="label-title">{{ metadata.keyAttribute }}</label>
              <prism-editor
                v-model="dataItem[metadata.keyAttribute]"
                class="my-editor"
                :highlight="highlighterJS"
                line-numbers
              />
            </div>
            <div v-else-if="fileTypes.includes(metadata.type)" class="pl-2 pb-2 form-field mb-5">
              <label class="label-title">{{ metadata.keyAttribute }}</label>
              <v-img v-show="metadata.type === 'photo'" class="mt-2" width="300" :src="dataItem[metadata.keyAttribute]" cover />
              <p>
                <a :href="dataItem[metadata.keyAttribute]" target="_blank">{{ dataItem[metadata.keyAttribute] }}</a>
              </p>
              <v-btn
                class="mt-2"
                color="primary"
                @click="
                  isShowDialogImportFileMedia = true;
                  keyAttributeFileMedia = metadata.keyAttribute;
                  fileTypeMedia = metadata.type;
                "
                v-text="'Choose File'"
              />
            </div>
            <v-dialog
              v-else-if="['VAutocomplete'].includes(getComponentInput(metadata))"
              v-model="dialog"
              width="100%"
            >
              <template v-slot:activator="{ on, attrs }">
                <VAutocomplete
                  :id="'keyAttribute_'+metadata.keyAttribute"
                  :multiple="isMultiple(metadata)"
                  :label="metadata.keyAttribute"
                  :items="getOptionsField(metadata)"
                  v-model="dataItem[metadata.keyAttribute]"
                  readonly
                  item-text="title"
                  item-value="id"
                  outlined
                  dense
                  color="red lighten-2"
                  v-bind="attrs"
                  v-on="on"
                  @click="dialogCurent = metadata.keyAttribute;"
                >
                  <template v-slot:selection="{ item }">
                    <v-list-item  @click="dialogCurent = metadata.keyAttribute; dialog = true">
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ item.id }}
                        </v-list-item-title>
                        <v-list-item-subtitle v-text="item" style="width: 15rem;"/>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </VAutocomplete>
              </template>
              <v-card v-if="dialogCurent === metadata.keyAttribute">
                <v-toolbar dark color="primary">
                  <v-toolbar-title>{{
                    itemId
                      ? `Update ${metadata.keyAttribute}`
                      : `Addd ${metadata.keyAttribute}`
                  }}</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-toolbar-items>
                    <v-btn dark text @click="dialog = false; dialogCurent = '';">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-toolbar-items>
                </v-toolbar>
                <v-data-table
                  v-model:sort-by="sortBy"
                  :headers="headerTable(getOptionsField(metadata))"
                  :items="formatDataTable(getOptionsField(metadata))"
                  :items-per-page="15"
                  :search="searchItems"
                  class="elevation-1"
                >
                  <template v-slot:top>
                    <v-text-field
                      v-model="searchItems"
                      class="pa-2 mt-5"
                      label="Search"
                    ></v-text-field>
                  </template>
                  <template v-slot:item.actions="{ item }">
                    <v-checkbox
                      v-if="valueCheckboxRef(item.id,metadata.keyAttribute, metadata.type)"
                      readonly
                      v-model="checkboxChecked"
                      @click="handelCheckboxRef(item.id,metadata.keyAttribute, metadata.type)"
                    ></v-checkbox>
                    <v-checkbox
                      v-else
                      readonly
                      :model-value="false"
                      @click="handelCheckboxRef(item.id,metadata.keyAttribute, metadata.type)"
                    ></v-checkbox>
                  </template>
                  <template v-slot:item.dataItemObjs="{ item }">
                    <!-- {{item.dataItemObjs}}  -->
                    {{ lengthObj(item.dataItemObjs) }}
                  </template>
                  <template v-slot:item.dataItemObj="{ item }">
                    {{ lengthObj(item.dataItemObj) }}
                  </template>
                  <template v-slot:item.childDataObjs="{ item }">
                    {{ lengthObj(item.childDataObjs) }}
                  </template>
                  <template v-slot:item.dataObj="{ item }">
                    {{ lengthObj(item.dataObj) }}
                  </template>
                </v-data-table>
              </v-card>
            </v-dialog>
            <component
              :is="getComponentInput(metadata)"
              v-else
              v-model="dataItem[metadata.keyAttribute]"
              :items="getOptionsField(metadata)"
              outlined
              dense
              item-text="title"
              item-value="id"
              :label="metadata.keyAttribute"
              :extensions="extensions"
              :placeholder="metadata.keyAttribute"
              :multiple="isMultiple(metadata)"
            />
          </div>
        </v-card-text>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
// Vuex
import { sync, get } from 'vuex-pathify';
import { VTextField, VAutocomplete, VTextarea, VSelect } from 'vuetify/lib';
import {
  // component
  TiptapVuetify,
  // extensions
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Code,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  Blockquote,
  HardBreak,
  HorizontalRule,
  History,
  Image,
  Table,
  TableCell,
  TableHeader,
  TableRow,
} from 'tiptap-vuetify';
import { PrismEditor } from 'vue-prism-editor';
import 'vue-prism-editor/dist/prismeditor.min.css';
import { highlight, languages } from 'prismjs';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript';
import 'prismjs/themes/prism-tomorrow.css';
import { cloneDeep } from 'lodash';
import { FILE_TYPE } from '../../manageMedia/constant'

export default {
  name: 'DialogItemFormUpdate',
  components: {
    VTextField,
    VAutocomplete,
    VTextarea,
    TiptapVuetify,
    VSelect,
    PrismEditor,
  },
  data: () => ({
    extensions: [
      History,
      Table,
      TableCell,
      TableHeader,
      TableRow,
      Blockquote,
      Link,
      Underline,
      Strike,
      Italic,
      ListItem, // if you need to use a list (BulletList, OrderedList)
      BulletList,
      OrderedList,
      Image,
      [
        Heading,
        {
          // Options that fall into the tiptap's extension
          options: {
            levels: [1, 2, 3, 4, 5, 6, 7],
          },
        },
      ],
      Bold,
      Link,
      Code,
      HorizontalRule,
      Paragraph,
      HardBreak, // line break on Shift + Ctrl + Enter
    ],
    valid: true,
    dataForm: {},
    showRawHTML: {},
    dialog: false,
    dialogChildDataObjs: false,
    dialogCurent: '',
    fileTypes: Object.values(FILE_TYPE),
    sortBy: [],
    searchItems: '',
    checkboxChecked: true,
  }),
  computed: {
    keyAttributes() {
      const keyAttributes = [];
      let appMetadata = [];
      if (this.isAddChildMetaData) {
        if (this.viewChildAppId === this.dataSeletectedChildApp.id) {
        appMetadata = this.dataSeletectedChildApp.metadata;
      } else {
        try {
          appMetadata = this.dataSeletectedChildApp.metadata.find(
            (metadata) => metadata.keyAttribute === this.viewChildAppId
          ).metadata;
        } catch (error) {}
      }
      } else {
        if (this.viewAppId === this.dataSeletectedApp.id) {
        appMetadata = this.dataSeletectedApp.metadata;
      } else {
        try {
          appMetadata = this.dataSeletectedApp.metadata.find(
            (metadata) => metadata.keyAttribute === this.viewAppId
          ).metadata;
        } catch (error) {}
      }
      }
      appMetadata.forEach((metadata) => {
        // array and object Will update in the near future
        if (metadata.type !== 'array' && metadata.type !== 'object') {
          keyAttributes.push(metadata);
        }
      });
      return keyAttributes;
    },
    ...sync('manageApps', [
      'isShowDialogItemFormUpdate',
      'isAddChildMetaData',
      'dataChildAppsById',
      'dataSeletectedChildApp',
      'appItemId',
      'isShowDialogImportFileMedia',
      'keyAttributeFileMedia',
      'fileTypeMedia',
      'itemId',
    ]),
    ...get('manageApps', [
      'viewChildAppId',
      'viewAppId',
      'dataSeletectedApp',
      'params',
      'dataItem',
      'query',
    ]),
  },
  watch: {
    isShowDialogImportFileMedia: {
      handler(newVal, oldVal) {
        if (!newVal) {
          this.$forceUpdate();
        }
      },
    },
  },
  methods: {
    lengthObj(data) {
      // console.log('dataNedd to length', data.length)
      let res = null;
      if (data) {
        res = data.length;
      } else {
        res = data;
      }
      return res;
    },
    valueCheckboxRef(id, dataModel, metadataType) {
      const currentValue =  this.dataItem[dataModel] || [];
      if (metadataType === 'refs_array') {
        return currentValue.includes(id);
      }
      return currentValue == id;
    },
    handelCheckboxRef(id, dataModel, metadataType) {
      if (metadataType === 'refs_array') {
        if (!Array.isArray(this.dataItem[dataModel])) {
          this.dataItem[dataModel] = [];
        }
        if (this.dataItem[dataModel].includes(id)) {
          this.dataItem[dataModel] =  this.dataItem[dataModel].filter(ref => ref != id);
        } else {
          this.dataItem[dataModel].push(id)
        }
      } else {
        this.dataItem[dataModel] = id;
      }
    },
    formatDataTable(data) {
      const res = [];
      data.forEach((item) => {
        const dataTitle = JSON.parse(item.title);
        const dataTable = { ...dataTitle, id: item.id };

        res.push(dataTable);
      });

      return res;
    },
    headerTable(data) {
      const headerTable = [];
      for (const item of data) {
        const keyHeader = Object.keys(JSON.parse(item.title));
        for (const res of keyHeader) {
          headerTable.push(res);
        }
      }
      const myArrayWithNoDuplicates = headerTable.reduce(function (
        accumulator,
        element
      ) {
        if (accumulator.indexOf(element) === -1) {
          accumulator.push(element);
        }
        return accumulator;
      },
      []);
      const dataHeaderRes = [
        { text: 'Actions', value: 'actions', width: '4rem', sortable: false },
        { text: 'id', value: 'id', width: '15rem' },
      ];
      myArrayWithNoDuplicates.forEach((element) => {
        const res = {
          text: element,
          value: element,
          width: '15rem',
        };
        dataHeaderRes.push(res);
      });
      return dataHeaderRes;
    },
    getComponentInput(metadata) {
      const { referenceToApp = '', type = '', keyAttribute } = metadata;
      if (type === 'boolean') {
        return 'VAutocomplete';
      }
      if (type === 'text') {
        return 'VTextarea';
      }
      if (type === 'html') {
        return 'TiptapVuetify';
      }
      if (type === 'ref') {
        if (!referenceToApp) {
          return 'VTextField';
        }

        const referenceableToFieldApps =
          this.dataSeletectedApp.referenceableToFieldApps || [];
        let parentKeyAttribute = '';
        if (this.viewAppId !== this.dataSeletectedApp.id) {
          parentKeyAttribute = this.viewAppId;
        }
        const foundReferenceableToFieldApp = referenceableToFieldApps.find(
          (referenceableToFieldApp) =>
            referenceableToFieldApp.keyAttribute === keyAttribute &&
            referenceableToFieldApp.parentKeyAttribute === parentKeyAttribute
        );
        if (!foundReferenceableToFieldApp) return 'VTextField';
        return 'VAutocomplete';
      }
      if (type === 'refs_array') {
        return 'VAutocomplete';
      }
      return 'VTextField';
    },
    isReferenceableToField(metadata) {
      const { referenceToApp = '', type = '', keyAttribute } = metadata;
      const referenceableToFieldApps =
        this.dataSeletectedApp.referenceableToFieldApps || [];
      if (type !== 'ref' || !referenceToApp) {
        return false;
      }
      let parentKeyAttribute = '';
      if (this.viewAppId !== this.dataSeletectedApp.id) {
        parentKeyAttribute = this.viewAppId;
      }
      return !!referenceableToFieldApps.find(
        (referenceableToFieldApp) =>
          referenceableToFieldApp.keyAttribute === keyAttribute &&
          referenceableToFieldApp.parentKeyAttribute === parentKeyAttribute
      );
    },
    getOptionsField(metadata) {
      const { referenceToApp = '', type = '', keyAttribute } = metadata;
      const referenceableToFieldApps =
        this.dataSeletectedApp.referenceableToFieldApps || [];
      if (type === 'boolean') {
        return [
          { id: true, title: 'true' },
          { id: false, title: 'false' },
        ];
      }

      if (type !== 'refs_array' && type !== 'ref') {
        return false;
      }

      if (type === 'ref' && !referenceToApp) {
        return false;
      }

      if (type === 'refs_array' && !Array.isArray(this.dataItem[keyAttribute])) {
        this.$set(this.dataItem, keyAttribute, []);
      }

      let parentKeyAttribute = '';
      if (this.viewAppId !== this.dataSeletectedApp.id) {
        parentKeyAttribute = this.viewAppId;
      }
      const foundReferenceableToFieldApp = referenceableToFieldApps.find(
        (referenceableToFieldApp) =>
          referenceableToFieldApp.keyAttribute === keyAttribute &&
          referenceableToFieldApp.parentKeyAttribute === parentKeyAttribute
      );
      if (!foundReferenceableToFieldApp) return [];

      return foundReferenceableToFieldApp.items.map((item) => {
        const cloneItem = cloneDeep(item);
        delete cloneItem.id;
        return { id: item.id, title: JSON.stringify(cloneItem) };
      });
    },
    highlighterHTML(code) {
      return highlight(code, languages.html);
    },
    highlighterJS(code) {
      return highlight(code, languages.js);
    },
    validateForm() {
      const errorMessages = [];
      for (const keyAttribute in this.dataItem) {
        const metadata = this.keyAttributes.find(
          (item) => item.keyAttribute === keyAttribute
        );
        if (!metadata) {
          continue;
        }
        let dataValue = this.dataItem[keyAttribute];
        try {
          dataValue = dataValue.trim();
        } catch (error) {}
        if (!dataValue && metadata.required) {
          errorMessages.push(`${keyAttribute} is required`);
        }
      }
      errorMessages.forEach((message) => {
        this.$toast.error(message);
      });
      return !errorMessages.length;
    },
    isMultiple(metadata) {
      const { type = '' } = metadata;
      if (type === 'refs_array') {
        return true;
      }
      return false;
    },
    async save(more) {
      const valueCheck = this.validateForm();
      // if valueCheck = false then stop function save
      if (!valueCheck) {
        return false;
      }

      const resultAPI = await this.$store.dispatch(
        `manageApps/${this.itemId ? 'update' : 'create'}Item`,
        this.getTokenLogin()
      );
      if (!resultAPI.status) {
        if (Array.isArray(resultAPI.data)) {
          resultAPI.data.forEach((message) => {
            this.$toast.error(message);
          });
        } else {
          this.$toast.error(resultAPI.data.message);
        }
        return false;
      }
      this.$toast.success(`Successful ${this.itemId ? 'Update' : 'Add'}`);
      this.showProgressCircular();
      setTimeout(() => {
        this.itemId = ''
        this.$store.dispatch('manageApps/getItemApp', {
          tokenLogin: this.getTokenLogin(),
          params: this.params,
          filter: this.query,
        });
        // this.isShowDialogItemFormUpdate = false;

        if (more === 'more') {
          this.itemId = '';
          this.keyAttributes.forEach((item) => {
            this.dataItem[item.keyAttribute] = '';
          });
        } else {
          this.isShowDialogItemFormUpdate = false;
        }
      }, 2000);
    },
    async saveAddChild() {
      const valueCheck = this.validateForm();
      if (!valueCheck) {
        return false;
      }

      const resultAPI = await this.$store.dispatch(`manageApps/${this.itemId ? 'update' : 'create'}ChildItem`, this.getTokenLogin());

      if (!resultAPI.status) {
        if (Array.isArray(resultAPI.data)) {
          resultAPI.data.forEach((message) => {
            this.$toast.error(message);
          });
        } else {
          this.$toast.error(resultAPI.data.message);
        }
        return false;
      }
      this.dataItem = {}
      this.$toast.success(`Successful ${this.itemId ? 'Update' : 'Add'}`);
      this.showProgressCircular();
      setTimeout(() => {
        this.itemId = ''
        this.$store.dispatch('manageApps/getItemApp', {
          tokenLogin: this.getTokenLogin(),
          params: this.params,
          filter: this.query,
        });
        this.isShowDialogItemFormUpdate = false;
      }, 2000);
    },
  },

};
</script>

<style scoped lang='scss'>
.form-field {
  border: 1px solid;
  border-radius: 5px;
}
.label-title {
  position: relative; top: -10px; background: white;
}
::v-deep .tiptap-vuetify-editor {
  margin-bottom: 1rem;

  .tiptap-vuetify-editor__content {
    max-height: 30rem;
  }
}
::v-deep .v-data-table__wrapper > table > thead > tr:last-child > th {
  // width: 14rem;
  // background-color: red;
}
::v-deep .v-data-table {
  overflow-x: auto;
}
.my-editor {
  /* we dont use `language-` classes anymore so thats why we need to add background and text color manually */
  font-family: Courier, monospace;
  background: #2d2d2d;
  color: #ccc;
  font-size: 14px;
  line-height: 1.5;
  margin-bottom: 32px;
  max-height: 700px;
  text-overflow: initial;
  padding: 8px;
}
.prism-editor-wrapper .prism-editor__editor:focus,
.prism-editor-wrapper .prism-editor__textarea:focus {
  outline: none;
}
.prism-editor-wrapper .prism-editor__editor,
.prism-editor-wrapper .prism-editor__textarea {
  padding: 8px !important;
}
</style>
