<template>
  <v-row class="px-4">
    <v-col cols="6">
      <v-autocomplete
        v-model="selectedAppId"
        :items="dataApps"
        :filter="filterObject"
        clearable
        dense
        outlined
        color="blue-grey-lighten-2"
        item-title="title"
        item-value="id"
        label="Select App"
        @change="changeApp"
      >
        <template v-slot:selection="data">
          <v-list-item>
            <v-list-item-avatar>
              <img :src="data.item.image_url" />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                {{ data.item.title }}
                <span style="text-transform: capitalize"
                  >({{ data.item.type }})</span
                >
              </v-list-item-title>
              <v-list-item-subtitle v-html="data.item.description" />
            </v-list-item-content>
          </v-list-item>
        </template>
        <template v-slot:item="data">
          <v-list-item-avatar>
            <img :src="data.item.image_url" />
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>
              {{ data.item.title }}
              <span style="text-transform: capitalize"
                >({{ data.item.type }})</span
              >
            </v-list-item-title>
            <v-list-item-subtitle v-html="data.item.description" />
          </v-list-item-content>
        </template>
      </v-autocomplete>
    </v-col>
    <v-col v-show="dataViewSubApps.length" cols="3">
      <v-autocomplete
        v-model="viewAppId"
        :items="dataViewSubApps"
        dense
        outlined
        color="blue-grey-lighten-2"
        item-text="title"
        item-value="id"
        label="Select Sub App"
        clearable
        @change="changeViewSubApp"
        @click:clear="clearViewSubApp"
      />
    </v-col>
    <v-col v-show="viewAppId && viewAppId != dataSeletectedApp.id" cols="3">
      <!-- // -->
      <v-dialog v-model="dialog" width="100%">
        <template v-slot:activator="{ on, attrs }">
          <v-autocomplete
            v-bind="attrs"
            v-on="on"
            v-model="appItemId"
            :items="dataMainAppItems"
            dense
            outlined
            color="blue-grey-lighten-2"
            item-text="title"
            item-value="id"
            label="Select Item"
            @change="changeSelectItem"
          >
            <!-- <template v-slot:item="data">
          <p style="color:red" >{{data.item.id}}</p>
        </template> -->
          </v-autocomplete>
        </template>

        <v-card>
          <v-toolbar dark color="primary">
            <v-toolbar-title>{{
              itemId ? "Update DataObj" : "Add DataObj"
            }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn dark text @click="dialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
          <v-data-table
            :headers="headerTable(dataMainAppItems)"
            :items="formatDataTable(dataMainAppItems)"
            :items-per-page="5"
            class="elevation-1"
          >
            <template v-slot:item.actions="{ item }">
              <v-icon
                color="primary"
                x-large
                class="mr-2"
                @click="handelAddObj(item.id, dataMainAppItems)"
                >mdi-plus-box</v-icon
              >
            </template>
            <template v-slot:item.dataItemObjs="{ item }">
              {{ item.dataItemObjs || JSON }}
            </template>
            <template v-slot:item.dataItemObj="{ item }">
              {{ item.dataItemObj || JSON }}
            </template>
            <template v-slot:item.childDataObjs="{ item }">
              {{ item.childDataObjs.length }}
            </template>
            <template v-slot:item.dataObj="{ item }">
              {{ item.dataObj.length }}
            </template>
          </v-data-table>
        </v-card>
      </v-dialog>
      <!-- // -->
    </v-col>
    <v-col v-show="selectedAppId && allDataUsers.length" cols="3">
      <v-autocomplete
        v-model="params.userId"
        :items="optionUsers"
        label="User"
        item-text="title"
        item-value="id"
        dense
        outlined
        clearable
      />
    </v-col>
    <v-col
      v-show="viewAppId && (viewAppId == dataSeletectedApp.id || appItemId)"
      class="text-end mb-2"
    >
      <v-btn
        color="success"
        @click="clickAddItem"
        v-text="$t('user.userList.actions.add')"
      />
    </v-col>
  </v-row>
</template>

<script>
import { sync, get } from "vuex-pathify";
export default {
  name: "FilterFormSelectApp",
  data() {
    return {
      dataApps: [],
      selectedAppId: null,
      dataViewSubApps: [],
      dataMainAppItems: [],
      keyAttributeMainApp: [],
      allDataUsers: [],
      dialog: false,
    };
  },
  computed: {
    ...sync("manageApps", [
      "viewAppId",
      "appItemId",
      "dataSeletectedApp",
      "totalItem",
      "dataItems",
      "showTable",
      "isShowDialogItemFormUpdate",
      "isAddChildMetaData",
      "itemId",
      "dataItem",
      "query",
      "params",
    ]),
    adminGroupParams: sync("adminGroup/params"),
    optionUsers() {
      const optionUsers = this.allDataUsers.map((dataUser) => {
        return {
          title: `${dataUser?.fullName || ""}(${dataUser?.email || ""})`,
          id: dataUser.id,
        };
      });
      optionUsers.unshift({
        title: "All",
        id: "",
      });
      return optionUsers;
    },
  },
  async created() {
    this.viewAppId = "";
    this.dataSubApps = [];
    this.keyAttributeMainApp = [];
    this.dataMainAppItems = [];
    this.appItemId = "";
    this.totalItem = 0;
    this.dataItems = [];
    this.showTable = false;
    const dataApps = await this.$store.dispatch("manageApps/getDataApps", {
      tokenLogin: this.getTokenLogin(),
      isShowPublic: true,
    });
    this.dataApps = (this.getAppsOfUser(dataApps) || []).filter(
      (dataApp) => dataApp.dataGroup?.id
    );
    if (!this.isRoleUserLogin()) {
      this.allDataUsers = await this.$store.dispatch(
        "adminUser/fetchAllDataUserByRole",
        { tokenLogin: this.getTokenLogin(), role: "User" }
      );
    }
  },
  watch: {
    appItemId() {},
  },
  methods: {
    handelAddObj(id, array) {
      function isAdult(member) {
        return member.id === id;
      }
      const index = array.findIndex(isAdult);
      this.appItemId = array[index].id;
      this.dialog = false;
      this.showTable = false;
      if (!this.appItemId) return;
      setTimeout(() => {
        this.showTable = true;
      }, 10);
    },
    formatDataTable(data) {
      const res = [];
      data.forEach((item) => {
        const dataTitle = JSON.parse(item.title);
        const dataTable = { ...dataTitle, id: item.id };
        res.push(dataTable);
      });
      return res;
    },
    headerTable(data) {
      const headerTables = [];
      for (const item of data) {
        const keyHeader = Object.keys(JSON.parse(item.title));
        for (const res of keyHeader) {
          headerTables.push(res);
        }
      }
      const myArrayWithNoDuplicates = headerTables.reduce(function (
        accumulator,
        element
      ) {
        if (accumulator.indexOf(element) === -1) {
          accumulator.push(element);
        }
        return accumulator;
      },
      []);
      const dataHeaderRes = [
        { text: "Actions", value: "actions", width: "4rem", sortable: false },
      ];
      myArrayWithNoDuplicates.forEach((element) => {
        const res = {
          text: element,
          value: element,
          width: "15rem",
        };
        dataHeaderRes.push(res);
      });
      return dataHeaderRes;
    },
    async getDataApps() {
      const data = await this.$store.dispatch("manageApps/getDataApps", {
        tokenLogin: this.getTokenLogin(),
        isShowPublic: true,
      });
      return data;
    },
    filterObject(item, queryText) {
      if (!queryText) return true;
      const { title = "", description = "" } = item;
      return (title + description)
        .toLocaleLowerCase()
        .includes(queryText.toLocaleLowerCase());
    },
    clearViewSubApp() {
      this.viewAppId = this.dataSeletectedApp.id;
      this.appItemId = "";
      this.totalItem = 0;
      this.dataItems = [];
      this.showTable = false;
      setTimeout(() => {
        this.viewAppId = this.dataSeletectedApp.id;
        this.showTable = true;
      }, 10);
    },
    async changeApp() {
      this.viewAppId = this.selectedAppId;
      this.dataSubApps = [];
      this.keyAttributeMainApp = [];
      this.dataMainAppItems = [];
      this.appItemId = "";
      this.totalItem = 0;
      this.dataItems = [];
      this.showTable = false;
      this.query = {};
      this.params.userId = "";
      if (this.selectedAppId) {
        await this.$store.dispatch("manageApps/detailApp", {
          tokenLogin: this.getTokenLogin(),
          id: this.selectedAppId,
        });
        this.getDataSubApps();
        setTimeout(() => {
          this.showTable = true;
        }, 10);
      }
    },
    getDataSubApps() {
      const dataViewSubApps = [];
      const keyAttributeMainApp = [];
      this.dataSeletectedApp.metadata.forEach((metadata) => {
        if (metadata.type === "array") {
          dataViewSubApps.push({
            title: metadata.keyAttribute,
            id: metadata.keyAttribute,
          });
        } else {
          keyAttributeMainApp.push(metadata.keyAttribute);
        }
      });
      this.dataViewSubApps = dataViewSubApps;
      this.keyAttributeMainApp = keyAttributeMainApp;
    },
    async changeViewSubApp() {
      this.dataMainAppItems = [];
      this.appItemId = "";
      this.totalItem = 0;
      this.dataItems = [];
      this.showTable = false;
      this.query = {};
      if (!this.viewAppId) return;
      if (this.viewAppId !== this.dataSeletectedApp.id) {
        const dataMainAppItems =
          (await this.$store.dispatch(
            "manageApps/getAllItemMainApp",
            this.getTokenLogin()
          )) || [];
        this.dataMainAppItems = dataMainAppItems.map((dataMainAppItem) => {
          const dataItem = {};
          this.keyAttributeMainApp.forEach((keyAttribute) => {
            dataItem[keyAttribute] = dataMainAppItem[keyAttribute];
          });
          return {
            title: JSON.stringify(dataItem),
            id: dataMainAppItem.id,
          };
        });
      } else {
        setTimeout(() => {
          this.showTable = true;
        }, 10);
      }
    },
    changeSelectItem() {
      this.showTable = false;
      if (!this.appItemId) return;
      setTimeout(() => {
        this.showTable = true;
      }, 10);
    },
    clickAddItem() {
      this.dataItem = {};
      this.itemId = "";
      this.isAddChildMetaData = false
      this.isShowDialogItemFormUpdate = true;
    },
  },
};
</script>
