<template>
  <div>
    <filter-form-query-builder />
    <v-data-table class="table-data-app" hide-default-footer :items="dataItems" :headers="headerArray" :items-per-page="-1"
      @update:sort-by="handleUpdateSortBy" @update:sort-desc="handleUpdateSortDesc" fixed-header>
      <template v-slot:body>
        <tbody v-if="dataItems.length">
          <tr v-for="(data, dataItemIndex) in dataItems" :key="dataItemIndex + '_dataItems'">
            <td class="text-center">
              <v-menu offset-y>
                <template v-slot:activator="{ attrs, on }">
                  <v-btn icon v-bind="attrs" v-on="on">
                    <v-icon>mdi-dots-horizontal</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item @click="() => openFormEditItem(data)">
                    <v-list-item-title v-text="$t('user.userList.actions.edit')" />
                  </v-list-item>
                  <v-divider />
                  <v-list-item @click="() => clickDeleteItem(data.id)">
                    <v-list-item-title v-text="$t('user.userList.actions.delete')" />
                  </v-list-item>
                  <v-divider />
                  <v-list-item v-for="(item,i) in referenceableToFieldAppList " :key="i" :disabled="dataSeletectedApp && dataSeletectedApp.id !== viewAppId" @click="() => openFormEditChildItem(item,data)">
                      <v-list-item-title>{{ $t('user.userList.actions.add') }} {{ item ? item.keyAttribute : '' }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </td>
            <td>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon>
                    <v-icon
                      color="grey lighten-1"
                      size="18"
                      v-bind="attrs"
                      v-on="on"
                      @click="copyText(data.id)"
                      v-text="'mdi-content-copy'"
                    />
                  </v-btn>
                </template>
                <span v-text="data.id" />
              </v-tooltip>
            </td>
            <td v-for="(metadata, headerIndex) in headers" :key="dataItemIndex + '_dataItems_headerIndex_' + headerIndex">
              <span v-if="isMedia(metadata)">
                <img v-show="metadata.type === 'photo'" width="80" v-lazy="viewDataItem(metadata, data)" />
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon>
                      <v-icon
                        color="grey lighten-1"
                        size="18"
                        v-bind="attrs"
                        v-on="on"
                        @click="copyText(viewDataItem(metadata, data))"
                        v-text="'mdi-content-copy'"
                      />
                    </v-btn>
                  </template>
                  <span v-text="viewDataItem(metadata, data)" />
                </v-tooltip>
              </span>
              <pre v-else-if="metadata.type === 'json'" class="format-show-value" v-text="viewDataItem(metadata, data)"></pre>
              <span class="format-show-value" v-else-if="!isReferenceableToField(metadata)" v-text="viewDataItem(metadata, data)" />
              <span v-else-if="metadata.type === 'array'" style="font-size: 12px;">
                <p v-for="(ref, refIndex) in viewDataItem(metadata, data)" :key="dataItemIndex + '_dataItems_headerIndex_' + headerIndex + '_refIndex_' + refIndex">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <span style="font-weight: bold; cursor: pointer;" v-bind="attrs" v-on="on"
                        v-text="'Ref('+ ref.id +')'" @click="() => openFormEditChildData(metadata,data,ref)" />
                         <v-avatar @click="() => clickDeleteReferenceChildItem(metadata,data, ref)" tile size="13" style="cursor: pointer" ><img src="https://api.techupcorp.com/media/lCoxrooBHEE-qkZrziFx/file/ehcvisbqejvex/396113107_delete.png" alt="*"></v-avatar>
                         <v-icon @click="() => clickDeleteChildItem(metadata,data, ref)" size="13" color="black" class="mr-2 ml-2 font-weight-bold">mdi-link-variant-off</v-icon>
                    </template>
                    <pre class="format-show-value" v-text="ref" />
                  </v-tooltip>
                </p>
              </span>
              <span v-else-if="metadata.type === 'refs_array'" style="font-size: 12px;">
                <p v-for="(refId, refIndex) in viewDataItem(metadata, data)" :key="dataItemIndex + '_dataItems_headerIndex_' + headerIndex + '_refIndex_' + refIndex">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <span style="font-weight: bold; cursor: pointer;" v-bind="attrs" v-on="on"
                        v-text="'Ref('+ refId +')'" @click="() => openFormEditItem(data, metadata.keyAttribute)" />
                    </template>
                    <pre class="format-show-value" v-text="viewReferenceableToField(metadata, data, refIndex)" />
                  </v-tooltip>
                </p>
              </span>
              <v-tooltip v-else bottom style="font-size: 12px;">
                <template v-slot:activator="{ on, attrs }">
                  <span @click="() => openFormEditItem(data, metadata.keyAttribute)" style="font-weight: bold; cursor: pointer;" v-bind="attrs" v-on="on"
                    v-text="viewDataItem(metadata, data)" />
                </template>
                <pre class="format-show-value" v-text="viewReferenceableToField(metadata, data)" />
              </v-tooltip>
            </td>
            <td>
              {{ data['updatedAt'] || data['createdAt'] }}
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr class="v-data-table__empty-wrapper">
            <td colspan="4">
              No data available
            </td>
          </tr>
        </tbody>
      </template>
    </v-data-table>
    <v-row v-if="dataItems.length" class="ml-2 mt-4">
      <v-col cols="4">
        <v-pagination v-model="params.offset" :length="getPageTotal(totalItem, params.limit)" />
      </v-col>
      <v-col cols="4">
        <v-select v-model="params.limit" :items="limitOptions" label="Rows per page" dense solo />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { get, sync } from 'vuex-pathify'
import FilterFormQueryBuilder from './FilterFormQueryBuilder'
import { cloneDeep } from 'lodash'
import { FILE_TYPE } from '../../manageMedia/constant'

export default {
  name: 'TableManageApp',
  components: {
    FilterFormQueryBuilder,
  },
  data() {
    return {}
  },
  computed: {
    referenceableToFieldAppList() {
      if (this.dataSeletectedApp?.referenceableToFieldApps) {
        return this.dataSeletectedApp?.referenceableToFieldApps
      } else {
        return []
      }
    },
    itemAttributes() {
      const keyAttributes = []
      let appMetadata = [];
      if (!this.viewAppId || (this.viewAppId === this.dataSeletectedApp.id)) {
        appMetadata = this.dataSeletectedApp.metadata
      } else {
        try {
          appMetadata = this.dataSeletectedApp.metadata.find(metadata => metadata.keyAttribute === this.viewAppId).metadata
        } catch (error) {
        }
      }
      appMetadata.forEach((metadata) => {
        if (metadata.type !== 'object') {
          keyAttributes.push(metadata)
        }
      });
      return keyAttributes
    },
    headers() {
      return this.itemAttributes.filter(metadata => metadata.type !== 'html')
    },
    headerArray() {
      const headerArray = [
        {
          text: this.$t('user.userList.table.actions'),
          align: 'center',
          sortable: false,
          value: 'actions',
          class: ['primary--text', 'font-weight-bold'],
        },
        {
          text: 'id',
          align: 'start',
          sortable: false,
          value: 'id',
          class: ['primary--text', 'font-weight-bold'],
        },
      ]
      this.headers.forEach((metadata) => {
        headerArray.push({
          text: metadata.keyAttribute,
          align: 'start',
          sortable: metadata.type === 'ref',
          value: metadata.keyAttribute,
          class: ['primary--text', 'font-weight-bold'],
          width: '15rem',
        })
      });
      headerArray.push({
        text: 'updated at',
        align: 'start',
        sortable: false,
        value: 'updated_at',
        class: ['primary--text', 'font-weight-bold'],
      })
      return headerArray
    },
    ...get('layout', [
      'limitOptions',
    ]),
    ...sync('manageApps', [
      'dataChildApp',
      'dataSeletectedChildApp',
      'refChildApp',
      'dataMetaItemDelete',
      'appChildItemId',
      'viewChildAppId',
      'itemChildData',
      'rowItemChild',
      'isAddChildMetaData',
      'viewAppId',
      'appItemId',
      'dataSeletectedApp',
      'params',
      'totalItem',
      'dataItems',
      'showTable',
      'itemId',
      'isShowDialogItemFormUpdate',
      'dataItem',
      'query',
    ]),
  },
  watch: {
    'params.offset': {
      handler(newVal, oldVal) {
        // when change offset then call api
        this.getItems()
      },
      deep: true,
    },
    'params.limit': {
      handler(newVal, oldVal) {
        // when change limit then call api and offset start = 1
        if (this.params.offset !== 1) {
          this.params.offset = 1
        } else {
          this.getItems()
        }
      },
      deep: true,
    },
    'params.userId': {
      handler(newVal, oldVal) {
        // when change limit then call api and offset start = 1
        if (this.params.offset !== 1) {
          this.params.offset = 1
        } else {
          this.getItems()
        }
      },
      deep: true,
    },
  },
  created() {
    this.initData()
  },
  methods: {
    initData(orderAttribute = '', order = '') {
      this.totalItem = 0
      this.dataItems = []
      this.params.orderAttribute = orderAttribute
      this.params.order = order
      if (this.params.offset !== 1) {
        this.params.offset = 1
      } else {
        this.getItems()
      }
    },
    handleUpdateSortBy(column) {
      this.initData(column[0], 'asc')
    },
    handleUpdateSortDesc(isDesc) {
      if (!isDesc.length) {
        this.initData();
      } else if (isDesc[0]) {
        this.initData(this.params.orderAttribute, 'desc');
      }
    },
    isMedia(metadata) {
      const { type = '' } = metadata
      return Object.values(FILE_TYPE).includes(type)
    },
    isReferenceableToField(metadata) {
      const { referenceToApp = '', type = '', keyAttribute } = metadata;
      const referenceableToFieldApps = this.dataSeletectedApp.referenceableToFieldApps || [];
      if (type !== 'ref' && type !== 'refs_array' && type !== 'array') {
        return false;
      }
      if (type === 'array') {
        return true;
      }
      let parentKeyAttribute = '';
      if (this.viewAppId !== this.dataSeletectedApp.id) {
        parentKeyAttribute = this.viewAppId;
      }
      return !!referenceableToFieldApps.find(referenceableToFieldApp => referenceableToFieldApp.keyAttribute === keyAttribute && referenceableToFieldApp.parentKeyAttribute === parentKeyAttribute)
    },
    viewReferenceableToField(metadata, dataItem, indexItem = 0) {
      const { keyAttribute, type = '' } = metadata
      const rawValue = dataItem[keyAttribute]
      const referenceableToField = dataItem['$' + keyAttribute] || rawValue;
      const cloneItem = type === 'refs_array' ? cloneDeep(referenceableToField[indexItem]) : cloneDeep(referenceableToField)
      try {
        delete cloneItem.id
      } catch (error) { }
      return JSON.stringify(cloneItem, undefined, 2);
    },
    viewDataItem(metadata, dataItem) {
      const { referenceToApp = '', type = '', keyAttribute } = metadata;
      const rawValue = dataItem[keyAttribute]
      if (!rawValue) { return type === 'refs_array' ? [] : '' }

      const referenceableToFieldApps = this.dataSeletectedApp.referenceableToFieldApps || [];
      if (type !== 'ref' || !referenceToApp) {
        return rawValue;
      }
      if (type !== 'array' || !referenceToApp) {
        return rawValue;
      }
      let parentKeyAttribute = ''
      if (this.viewAppId !== this.dataSeletectedApp.id) {
        parentKeyAttribute = this.viewAppId
      }
      const referenceableToFieldApp = referenceableToFieldApps.find(referenceableToFieldApp => referenceableToFieldApp.keyAttribute === keyAttribute && referenceableToFieldApp.parentKeyAttribute === parentKeyAttribute)
      if (referenceableToFieldApp) {
        return `Ref(${rawValue})`
      }
      return rawValue
    },
    async getItems() {
      await this.$store.dispatch('manageApps/getItemApp', {
        tokenLogin: this.getTokenLogin(),
        params: this.params,
        filter: this.query,
      })
    },
    async clickDeleteItem(itemId) {
      const confirmed = await this.$swal({
        title: 'Item Delete Confirmation',
        text: 'Are you sure you want to delete this item?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Delete',
        confirmButtonColor: '#c32c39',
        cancelButtonText: 'Cancel',
        cancelButtonColor: '#9a9ea1',
        reverseButtons: true,
      })
      if (!confirmed.value) return false
      const resultAPI = await this.$store.dispatch('manageApps/deleteItem', {
        tokenLogin: this.getTokenLogin(),
        itemId,
      })
      if (!resultAPI.status) {
        this.$toast.error(resultAPI.data.message)
        return false
      }
      this.$toast.success(resultAPI.message)
      this.showProgressCircular()
      setTimeout(() => {
        this.getItems()
      }, 2000)
    },
    async clickDeleteReferenceChildItemArrayRef(item, data, refId, refIndex) {
      await this.$store.dispatch('manageApps/detailChildApp', {
        tokenLogin: this.getTokenLogin(),
        id: item.referenceToApp,
      })
      this.appChildItemId = refId
      this.viewChildAppId = data.id
      const indexItem = data[item.keyAttribute].findIndex(res => res === refId)
      const cloneDataListKeyAttribute = cloneDeep(data[item.keyAttribute])
      cloneDataListKeyAttribute.splice(indexItem, 1)
      this.dataItem = {}
      this.dataItem[item.keyAttribute] = cloneDataListKeyAttribute
      const confirmed = await this.$swal({
        title: 'Item Delete Confirmation',
        text: 'Are you sure you want to delete this item?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Delete',
        confirmButtonColor: '#c32c39',
        cancelButtonText: 'Cancel',
        cancelButtonColor: '#9a9ea1',
        reverseButtons: true,
      })
      if (!confirmed.value) return false

      const resultAPI = await this.$store.dispatch('manageApps/deleteReferenceChildItemArrayRef', {
        tokenLogin: this.getTokenLogin(),
        refId,
      })
      if (!resultAPI.status) {
        this.$toast.error(resultAPI.data.message)
        return false
      }
      this.$toast.success(resultAPI.message || 'Successful delete')
      this.showProgressCircular()
      setTimeout(() => {
        this.getItems()
      }, 2000)
    },
    async clickDeleteReferenceChildItem(item, data, ref) {
      let dataMetaItem = {}
      item.metadata.forEach(element => {
        const isMetaData = !!ref[element.keyAttribute]
        if (isMetaData) {
          dataMetaItem = element
        }
      })
      await this.$store.dispatch('manageApps/detailChildApp', {
        tokenLogin: this.getTokenLogin(),
        id: dataMetaItem.referenceToApp,
      })
      this.dataMetaItemDelete = dataMetaItem
      const confirmed = await this.$swal({
        title: 'Item Delete Confirmation',
        text: 'Are you sure you want to delete this item?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Delete',
        confirmButtonColor: '#c32c39',
        cancelButtonText: 'Cancel',
        cancelButtonColor: '#9a9ea1',
        reverseButtons: true,
      })
      const itemId = ref[`${dataMetaItem.keyAttribute}`]
      this.viewChildAppId = item.keyAttribute
      this.refChildApp = ref
      this.appChildItemId = ref[`${dataMetaItem.keyAttribute}`]
      this.dataChildApp = data
      if (!confirmed.value) return false
      const resultAPI = await this.$store.dispatch('manageApps/deleteReferenceChildItem', {
        tokenLogin: this.getTokenLogin(),
        itemId,
      })
      if (!resultAPI.status) {
        this.$toast.error(resultAPI.data.message)
        return false
      }
      this.$toast.success(resultAPI.message || 'Successful delete')
      this.showProgressCircular()
      setTimeout(() => {
        this.getItems()
      }, 2000)
    },
    async clickDeleteChildItemArrayRef(item, data, refId, refIndex) {
      this.itemId = data.id
      const indexItem = data[item.keyAttribute].findIndex(res => res === refId)
      const cloneDataListKeyAttribute = cloneDeep(data[item.keyAttribute])
      cloneDataListKeyAttribute.splice(indexItem, 1)
      this.dataItem = {}
      this.dataItem[item.keyAttribute] = cloneDataListKeyAttribute
      const confirmed = await this.$swal({
        title: 'Item Unlink Confirmation',
        text: 'Are you sure you want to unlink this item?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Unlink',
        confirmButtonColor: '#c32c39',
        cancelButtonText: 'Cancel',
        cancelButtonColor: '#9a9ea1',
        reverseButtons: true,
      })

      if (!confirmed.value) return false
      const resultAPI = await this.$store.dispatch('manageApps/updateChildItemArrayRef', this.getTokenLogin())
      if (!resultAPI.status) {
        this.$toast.error(resultAPI.data.message)
        return false
      }
      this.$toast.success(resultAPI.message || 'Successful Unlink')
      this.showProgressCircular()
      setTimeout(() => {
        this.getItems()
      }, 2000)
    },
    async clickDeleteChildItem(item, data, ref) {
      let dataMetaItem = {}
      item.metadata.forEach(element => {
        const isMetaData = !!ref[element.keyAttribute]
        if (isMetaData) {
          dataMetaItem = element
        }
      })
      await this.$store.dispatch('manageApps/detailChildApp', {
        tokenLogin: this.getTokenLogin(),
        id: dataMetaItem.referenceToApp,
      })
      this.dataMetaItemDelete = dataMetaItem
      const confirmed = await this.$swal({
        title: 'Item Unlink Confirmation',
        text: 'Are you sure you want to unlink this item?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Unlink',
        confirmButtonColor: '#c32c39',
        cancelButtonText: 'Cancel',
        cancelButtonColor: '#9a9ea1',
        reverseButtons: true,
      })
      const itemId = ref[`${dataMetaItem.keyAttribute}`]
      this.viewChildAppId = item.keyAttribute
      this.refChildApp = ref
      this.appChildItemId = data.id
      if (!confirmed.value) return false
      const resultAPI = await this.$store.dispatch('manageApps/deleteChildItem', {
        tokenLogin: this.getTokenLogin(),
        itemId,
      })
      if (!resultAPI.status) {
        this.$toast.error(resultAPI.data.message)
        return false
      }
      this.$toast.success(resultAPI.message || 'Successful Unlink')
      this.showProgressCircular()
      setTimeout(() => {
        this.getItems()
      }, 2000)
    },
    openFormEditItem(data, scrollKeyAttribute = '') {
      this.isAddChildMetaData = false;
      this.itemId = data.id
      const dataItem = {}
      this.itemAttributes.forEach(metadata => {
        dataItem[metadata.keyAttribute] = data[metadata.keyAttribute]
      })
      this.dataItem = dataItem
      this.isShowDialogItemFormUpdate = true
      if (scrollKeyAttribute) {
        setTimeout(() => {
          const elemtKeyAttribute = document.getElementById('keyAttribute_' + scrollKeyAttribute);
          elemtKeyAttribute.scrollIntoView({
            behavior: 'smooth'
          });
          elemtKeyAttribute.click();
        }, 300);
      }
    },
    async openFormEditChildDataArrayRef(item, data, refId, refIndex) {
      await this.$store.dispatch('manageApps/detailChildApp', {
        tokenLogin: this.getTokenLogin(),
        id: item.referenceToApp,
      })
      this.dataItem = data[`$${item.keyAttribute}`][refIndex]
      this.itemId = this.dataItem.id
      this.viewChildAppId = item.referenceToApp
      this.isAddChildMetaData = true;
      this.isShowDialogItemFormUpdate = true;
    },
    async openFormEditChildData(item, data, ref) {
      let dataMetaItem = {}
      item.metadata.forEach(element => {
        const isMetaData = !!ref[element.keyAttribute]
        if (isMetaData) {
          dataMetaItem = element
        }
      })
      await this.$store.dispatch('manageApps/detailChildApp', {
        tokenLogin: this.getTokenLogin(),
        id: dataMetaItem.referenceToApp,
      })
      this.itemId = ref[`${dataMetaItem.keyAttribute}`]
      this.dataItem = ref[`$${dataMetaItem.keyAttribute}`]
      this.itemChildData = dataMetaItem
      this.rowItemChild = data
      this.viewChildAppId = dataMetaItem.referenceToApp
      this.appChildItemId = ref[dataMetaItem.keyAttribute]
      // this.dataItem = dataItem
      this.isAddChildMetaData = true;
      this.isShowDialogItemFormUpdate = true;
    },
    async openFormEditChildItem(item, data) {
      await this.$store.dispatch('manageApps/detailChildApp', {
        tokenLogin: this.getTokenLogin(),
        id: item?.referenceToApp,
      })
      this.itemChildData = item
      this.rowItemChild = data
      this.dataItem = {};
      this.viewChildAppId = item?.referenceToApp
      this.appChildItemId = data?.id
      this.isAddChildMetaData = true;
      this.itemId = ''
      this.isShowDialogItemFormUpdate = true;
    },
    async copyText (textToCopy) {
      if (navigator.clipboard && window.isSecureContext) {
        await navigator.clipboard.writeText(textToCopy)
      } else {
        const textArea = document.createElement('textarea');
        textArea.value = textToCopy;
        // Move textarea out of the viewport so it's not visible
        textArea.style.position = 'absolute'
        textArea.style.left = '-999999px'
        document.body.prepend(textArea)
        textArea.select()
        try {
          document.execCommand('copy')
        } catch (error) {
          console.error(error)
        } finally {
          textArea.remove()
        }
      }
    },
  },
}
</script>
<style scoped lang='scss'>
.v-data-table {
  margin-top: 0.25rem
}

// .table-data-app {
//   overflow: auto !important;
//   max-height: 25rem !important;
// }


.table-data-app{
	max-height: 25rem;
}
.v-data-table {
	overflow: auto;
}
.v-data-table ::v-deep .v-data-table__wrapper {
	overflow: unset;
}

.table-data-app > .v-data-table__wrapper > table > tbody > tr > td:first-child {
  position: sticky;
  left: 0;
  background-color: #FFFFFF;
  z-index: 1;
}

.table-data-app > ::v-deep .v-data-table__wrapper > table > thead > tr > th:first-child {
  background-color: #FFFFFF;
  z-index: 5;
  left: 0;
}

.table-data-app > .v-data-table__wrapper > table > tbody > tr > td p {
  margin-bottom: 3px;
}

.format-show-value {
  text-overflow: ellipsis;
  max-width: 10rem;
  max-height: 10rem;
  white-space: pre;
  overflow: hidden;
  display: inline-block;
  position: relative;
}

</style>
