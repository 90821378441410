<template>
  <v-container id="manage-apps-view" fluid tag="section">
    <material-card color="primary" icon="mdi-account-outline">
      <filter-form-select-app />
      <table-manage-app v-if="showTable" />
      <dialog-item-form-update />
      <dialog-import-file-media />
    </material-card>
  </v-container>
</template>

<script>
import { get } from 'vuex-pathify'
import FilterFormSelectApp from '../modules/all/manageApps/components/FilterFormSelectApp.vue'
import TableManageApp from '../modules/all/manageApps/components/TableManageApp.vue'
import DialogItemFormUpdate from '../modules/all/manageApps/components/DialogItemFormUpdate.vue'
import DialogImportFileMedia from '../modules/all/manageApps/components/DialogImportFileMedia'

export default {
  name: 'ManageApps',
  components: {
    FilterFormSelectApp,
    TableManageApp,
    DialogItemFormUpdate,
    DialogImportFileMedia,
  },
  computed: {
    ...get('manageApps', [
      'showTable',
    ]),
  },
  // Title page
  metaInfo: {
    titleTemplate: 'Manage Data Apps',
  },
}
</script>
